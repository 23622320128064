import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

type BannersDotsProps = {
  currentSlide: number;
  totalSlides?: number;
  onClick: (slide: number) => void;
  isVisible: boolean;
  isLoading: boolean;
};

export const BannersDots = ({
  onClick,
  totalSlides,
  currentSlide,
  isLoading,
  isVisible = true,
}: BannersDotsProps) => {
  const { t } = useTranslation('common');

  if (isLoading) {
    return <div className="h-[26px]" />;
  }

  if (!isVisible || !totalSlides) {
    return null;
  }

  return (
    <div
      data-testid="banners-dots"
      className={cn(
        'mt-5',
        'justify-center',
        'content-center',
        'gap-1.5',
        'flex'
      )}
    >
      {new Array(Math.ceil(totalSlides)).fill(null).map((_, index) => (
        <button
          aria-label={t('Go to slide {{slide}}', { slide: index + 1 })}
          type="button"
          key={index}
          onClick={() => onClick(index)}
          className={cn(
            'rounded-full',
            'cursor-pointer',
            'h-1.5',
            index === currentSlide
              ? ['bg-primary-400', 'w-3']
              : ['bg-gray-300', 'w-1.5']
          )}
        />
      ))}
    </div>
  );
};
